import { SvgIconComponent } from '@mui/icons-material';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

export interface Advantage {
  title: string;
  text: string;
  icon: SvgIconComponent;
}

export const advantagesData: Advantage[] = [
  {
    title: 'Made to Order',
    text: 'All Pieces made to order for you',
    icon: InventoryIcon,
  },
  {
    title: 'Free Delivery',
    text: 'Free Delivery for order World-wide',
    icon: LocalShippingIcon,
  },
  {
    title: 'Free Exchange',
    text: 'Free Exchange on all products',
    icon: SwapHorizIcon,
  },
];

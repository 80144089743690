import React, { useState } from 'react';
import { Box, IconButton, Drawer, Typography, Button, InputAdornment, TextField } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4'; 
import Brightness7Icon from '@mui/icons-material/Brightness7'; 
import SearchIcon from '@mui/icons-material/Search';
import Logo from '../../image/logo/darkThemeLogo.png';

interface MobileMenuProps {
  pages: { name: string; path: string }[];
  onNavigate: (path: string) => void;
  theme: 'light' | 'dark';
  onThemeChange: (theme: 'light' | 'dark') => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ pages, onNavigate, theme, onThemeChange }) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newState: boolean) => () => {
    setOpen(newState);
  };

  const handleNavigation = (path: string) => {
    onNavigate(path);
    setOpen(false);
  };


  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton sx={{ color: 'inherit' }} onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
            maxWidth: '320px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#121212'
          },
        }}
      >
        <Box sx={{
          flex: '0 0 auto',
          p: 2,
          textAlign: 'center',
        }}>
          <Box onClick={() => handleNavigation('/')} sx={{ display: 'flex', justifyContent: 'center', flexShrink: 0, cursor: 'pointer' }}>
            <img
              src={Logo}
              alt="Logo"
              style={{ height: 'clamp(90px, 8vw, 100px)', width: 'auto' }}
            />
          </Box>
        </Box>

        <Box sx={{
          flex: '1 1 auto',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}>
          {pages.map((page, index) => {
            const isActive = window.location.pathname === page.path;
            return (
              <React.Fragment key={page.name}>
                <Button
                  onClick={() => handleNavigation(page.path)}
                  variant="contained"
                  sx={{
                    width: '100%',
                    backgroundColor: isActive ? '#3d3d3d' : '#2c2c2c',
                    color: '#ffffff',
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 2,
                    '&:hover': {
                      backgroundColor: isActive ? '#3d3d3d' : '#4d4d4d',
                    },
                  }}
                >
                  {page.name}
                </Button>

                {index === 0 && (
                  <Box sx={{
                    width: '100%',
                    backgroundColor: '#2c2c2c',
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 2,
                    height: '40px',
                    alignItems: 'center',
                    
                  }}>
                    <TextField
                      placeholder="Search"
                      variant="standard"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon fontSize="small" sx={{ color: '#ffffff' }} />
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        sx: {
                          padding: '0',
                          '&::placeholder': { color: '#ffffff' }, 
                        },
                      }}
                      inputProps={{
                        style: { color: '#ffffff' },
                      }}
                      sx={{
                        backgroundColor: 'transparent',
                        borderRadius: '8px',
                        fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                      }}
                    />

                  </Box>
                )}
              </React.Fragment>
            );
          })}
        </Box>

        <Box sx={{
          flex: '0 0 auto',
          px: 2,
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Button
            onClick={() => onNavigate('/Authentication')}
            variant="contained"
            sx={{
              width: '100%',
              backgroundColor: '#000000',
              color: '#ffffff',
              borderRadius: '8px',
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: '#1A1A1A',
              },
            }}
          >
            Log In / Sign In
          </Button>
        </Box>

        <Box sx={{
          flex: '0 0 auto',
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}>
          <Button
            onClick={() => onThemeChange('light')}
            variant="contained"
            startIcon={<Brightness7Icon sx={{ color: theme === 'light' ? '#ffffff' : '#aaaaaa' }} />}
            sx={{
              flex: 1,
              backgroundColor: theme === 'light' ? '#000000' : '#4D4D4D',
              color: theme === 'light' ? '#ffffff' : '#aaaaaa',
              borderRadius: '8px',
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: theme === 'light' ? '#1A1A1A' : '#3D3D3D',
              },
            }}
          >
            Light
          </Button>

          {/* Кнопка темной темы */}
          <Button
            onClick={() => onThemeChange('dark')}
            variant="contained"
            startIcon={<Brightness4Icon sx={{ color: theme === 'dark' ? '#ffffff' : '#aaaaaa' }} />}
            sx={{
              flex: 1,
              backgroundColor: theme === 'dark' ? '#000000' : '#4D4D4D',
              color: theme === 'dark' ? '#ffffff' : '#aaaaaa',
              borderRadius: '8px',
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: theme === 'dark' ? '#1A1A1A' : '#3D3D3D',
              },
            }}
          >
            Dark
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;

import React from 'react';
import { Box } from '@mui/material';
import HeroSection from '../components/home/HeroSection';
import NewCollections from '../components/home/NewCollections';
import PopularCategories from '../components/home/PopularCategories';
import RecentReviews from '../components/home/RecentReviews';
import StatisticsSection from '../components/home/StatisticsSection';
import SubscriptionSection from '../components/home/SubscriptionSection';
import AdvantagesSection from '../components/home/AdvantagesSection';
import FAQ from '../components/home/FAQ';
import NewProducts from '../components/home/NewProducts';
import MostPopular from '../components/home/MostPopular';
import HomeSection from '../components/home/HomeSection';

const Home: React.FC = () => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 6, 
    }}>
      <HomeSection />
      <MostPopular />
      <NewProducts />
      <NewCollections />
      <FAQ />
      <AdvantagesSection />
      <SubscriptionSection />
    </Box>
  );
};

export default Home;

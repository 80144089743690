import Main from "./pages/Main";

function App() {
 
  return (
    <Main/> 
  );
}

export default App;

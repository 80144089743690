import React from 'react';
import { Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from 'react-router-dom';

const CartButton: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        backgroundColor: '#ffffff', // Белый фон
        color: '#000000', // Черный текст
        borderRadius: '8px',
        padding: '8px 16px',
        textTransform: 'none',
        fontWeight: 'bold',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Легкая тень
        '&:hover': {
          backgroundColor: '#e6e6e6', // Чуть темнее цвет при наведении
          boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)', // Более выраженная тень при наведении
        },
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
      }}
    >
      <ShoppingCartIcon />
      Cart
    </Button>
  );
};

export default CartButton;

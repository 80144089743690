import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CollectionCard from '../cards/CollectionCard';
import { newCollectionsData } from '../../data/NewCollectionsData';

const NewCollections: React.FC = () => {
  return (
    <Box sx={{
      minHeight: 'calc(100vh - 140px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}>
      <Typography
        variant="h1"
        sx={{
          mb: 0.5,
          fontWeight: 'bold',
          fontSize: 'clamp(36px, 6vw, 72px)',
          textAlign: 'center',
        }}
      >
        Our Collections
      </Typography>


      <Grid container spacing={2} sx={{ display: 'flex', height: '100%', width: '100%',  flexGrow: 1, }}>
        {newCollectionsData.map((collection, index) => (
          <Grid key={index} size={{ xs: 12, sm: 12, md: 12, lg: 6}} sx={{ display: 'flex', minHeight: '100%', width: '100%', flexGrow: 1 }}>
            <CollectionCard
              title={collection.title}
              image={collection.image}
              description={collection.description}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default NewCollections;

import React, { useState } from 'react';
import { Box, Button, Checkbox, Divider, FormControlLabel, IconButton, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import { StyledTextField } from '../../styled/StyledTextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomButton from '../buttons/CustomButton';

interface RegistrationProps {
  onSwitch: () => void;
}

const Registration: React.FC<RegistrationProps> = ({ onSwitch }) => {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
  const phoneRegex = /^\+?\d{7,15}$/;

  const validateField = (field: string, value: string) => {
    switch (field) {
      case 'name':
        return value ? '' : 'Numele este necesar';
      case 'surname':
        return value ? '' : 'Prenumele este necesar';
      case 'email':
        if (!value) return 'Emailul este necesar';
        if (!emailRegex.test(value)) return 'Format email greșit';
        return '';
      case 'phone':
        if (!value) return 'Numărul de telefon este necesar';
        if (!phoneRegex.test(value)) return 'Număr de telefon greșit';
        return '';
      case 'password':
        if (!value) return 'Parola este necesară';
        if (!passwordRegex.test(value)) return 'Parola incorectă, minim 8 caractere, o majuscula si un simbol special';
        return '';
      default:
        return '';
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = () => {
    let newErrors = { ...errors };
    let isValid = true;

    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field as keyof typeof formData]);
      if (error) {
        isValid = false;
        newErrors[field as keyof typeof errors] = error;
      }
    });

    setErrors(newErrors);

    if (isValid) {
      console.log('Form is valid, proceed with registration');
    }
  };


  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '16px',
      gap: 4
    }}>
      <Box sx={{
        display: 'flex',
        flex: '0 0 auto',
        maxWidth: '600px',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2
      }}>

        <Typography
          variant="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: 'clamp(1.325rem, 2.5vw, 2.4rem)', 
          }}
        >
          Creati-va cont si alaturati-va noua
        </Typography>
        <Box sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1
        }}>
          <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#999999' }}>
            Ai deja cont?
          </Typography>
          <CustomButton
            text='Log In'
            onClick={onSwitch}
            defaultColor="#ffffff"
            hoverColor="#cccccc"
            textColor="#000000"
            fontSize = 'clamp(1rem, 2vw, 1rem)'
            maxWidth="clamp(100px, 10vw, 120px)" 
          />
        </Box>

        <Box sx={{
          display: 'flex',
          flex: '0 0 auto',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2
        }}>
          <Box sx={{
            display: 'flex',
            flex: '0 0 auto',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
              gap: 2,
            }}>
              <StyledTextField
                label="Nume"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={!!errors.name}
                helperText={errors.name}
                required
                sx={{
                  flex: 1,
                  minWidth: '250px'
                }}
              />
              <StyledTextField
                label="Prenume"
                name="surname"
                value={formData.surname}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={!!errors.surname}
                helperText={errors.surname}
                required
                sx={{
                  flex: 1,
                  minWidth: '250px'
                }}
              />
            </Box>
            <StyledTextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              onBlur={handleBlur}
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
              required
            />
            <StyledTextField
              label="Numar de telefon"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              onBlur={handleBlur}
              error={!!errors.phone}
              helperText={errors.phone}
              inputProps={{
                inputMode: 'tel', 
                pattern: '[0-9+]*', 
              }}
              onKeyPress={(e) => {
                if (!/[0-9+]/.test(e.key)) {
                  e.preventDefault(); 
                }
              }}
              fullWidth
              required
            />
            <StyledTextField
              label="Parola"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleInputChange}
              onBlur={handleBlur}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              required
            />
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column', 
            alignItems: 'flex-start',
            width: '100%',
            gap: 1,
          }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
              }
              label="Accept termenii și condițiile"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletterAccepted}
                  onChange={(e) => setNewsletterAccepted(e.target.checked)} 
                />
              }
              label="Doresc să primesc cele mai noi noutăți și oferte"
            />
          </Box>

          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: 'white', 
              color: 'black',
              borderRadius: '8px',
              textTransform: 'none',
              padding: '12px 24px',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)', 
                color: 'rgba(0, 0, 0, 0.85)', 
                transform: 'scale(1.025)',
              },
              transition: 'all 0.3s ease',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', 
            }}
            onClick={handleSubmit}
            disabled={!termsAccepted}
          >
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              Înregistrează-te
            </Typography>
          </Button>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: 1,
          }}>
            <Divider sx={{ flex: 1, height: '2px', backgroundColor: '#999999' }} />
            <Typography variant='h6' sx={{ mx: 2, color: '#999999' }}>
              Or register with
            </Typography>
            <Divider sx={{ flex: 1, height: '2px', backgroundColor: '#999999' }} />
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between', 
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}>
            <Box sx={{
              flex: 1,
              minWidth: '250px',
              borderColor: '#ccc',
              borderRadius: '8px',
              color: 'white',
              textTransform: 'none',
              padding: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              transition: 'all 0.3s ease',
              cursor: 'pointer',
                '&:hover': {
                  borderColor: '#999999',
                  transform: 'translateY(-2px)',
                },
              }}
              onClick={() => { /* Handle Google click event */ }}
            >
              <GoogleIcon sx={{ mr: 1 }} />
              <Typography variant='h6'>
                Google
              </Typography>
            </Box>

            <Box sx={{
              flex: 1,
              minWidth: '250px',
              borderColor: '#ccc',
              borderRadius: '8px',
              color: 'white',
              textTransform: 'none',
              padding: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              transition: 'all 0.3s ease',
              cursor: 'pointer',
              '&:hover': {
                borderColor: '#999999',
                transform: 'translateY(-2px)',
              },
            }}
              onClick={() => { /* Handle Instagram click event */ }}
            >
              <InstagramIcon sx={{ mr: 1 }} />
              <Typography variant='h6'>
                Instagram
              </Typography>
            </Box>
          </Box>
        </Box> 
      </Box>
    </Box>
  );
};

export default Registration;

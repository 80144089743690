import React from 'react';
import { Box, Typography } from '@mui/material';

interface PopularProductCardProps {
  title: string;
  image: string;
  description: string;
  price: string;
}

const PopularProductCard: React.FC<PopularProductCardProps> = ({ title, image, price }) => {
  return (
    <Box sx={{
        width: '100%',
        maxWidth: '375px',
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#eaeaea',
        p: 1,
        mx: 'auto',
        position: 'relative',
        cursor: 'pointer',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease', 
        '&:hover': {
          transform: 'translateY(-8px)', 
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)', 
        },
    }}>
        <Box sx={{
            width: '100%',
            borderRadius: '16px',
            paddingTop: '100%',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: '#f5f5f5',
            position: 'relative',
        }}/>

        <Box sx={{
            position: 'absolute',
            bottom: 16,
            left: 16,
            right: 16,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(8px)',
            borderRadius: '12px',
            padding: '8px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <Typography variant="h6"
                sx={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: 'clamp(1rem, 2vw, 1.25rem)',
                }}
            >
                {title}
            </Typography>
            <Typography  variant="body1"
                sx={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: 'clamp(1rem, 2vw, 1.25rem)',
                }}
            >
                {price}
            </Typography>
        </Box>
    </Box>
  );
};

export default PopularProductCard;

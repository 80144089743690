  export interface FooterLink {
    name: string;
    path: string;
  }
  
  export interface FooterSection {
    sectionName: string;
    links: FooterLink[];
  }


  export const simpleFooterLinks: FooterLink[] = [
    { name: 'Privacy Policy', path: '/privacy-policy' },
    { name: 'Terms of Service', path: '/terms-of-service' },
    { name: 'Cookie Policy', path: '/cookie-policy' },
    { name: 'Contact Us', path: '/contact' },
  ];
  
  export const footerData: FooterSection[] = [
    {
      sectionName: 'Company',
      links: [
        { name: 'About Us', path: '/about' },
        { name: 'Careers', path: '/careers' },
        { name: 'Press', path: '/press' },
      ],
    },
    {
      sectionName: 'Resources',
      links: [
        { name: 'Blog', path: '/blog' },
        { name: 'Help Center', path: '/help-center' },
        { name: 'Contact Us', path: '/contact' },
      ],
    },
    {
      sectionName: 'Legal',
      links: [
        { name: 'Privacy Policy', path: '/privacy-policy' },
        { name: 'Terms of Service', path: '/terms-of-service' },
        { name: 'Cookie Policy', path: '/cookie-policy' },
      ],
    },
  ];
  
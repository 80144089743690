import React, { useState } from 'react';
import { Box, Typography, IconButton, Collapse } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

interface CollectionCardProps {
  title: string;
  image: string;
  description: string;
}

const CollectionCard: React.FC<CollectionCardProps> = ({ title, image, description }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Box sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        borderRadius: '32px',
        overflow: 'hidden',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        color: '#ffffff',
        p: 1,
        minHeight: '300px',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
        <IconButton sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: '#ffffff',
            border: '2px solid #ffffff',
            borderRadius: '50%',
            width: 52,
            height: 52,
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
            },
        }}>
            <EastIcon />
        </IconButton>

        <Box sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(8px)',
            borderRadius: '24px',
            px: 2,
            py: 1,
            display: 'inline-block',
        }}>
            <Typography variant="h5"
                sx={{
                    fontWeight: 'bold',
                    fontSize: 'clamp(32px, 4vw, 48px)',
                }}
            >   
                {title}
            </Typography>
            
            <Collapse in={hovered}>
                <Typography
                    variant="body1"
                    sx={{
                    fontSize: 'clamp(16px, 2vw, 20px)',
                    mt: 1,
                    }}
                >
                    {description}
                </Typography>
            </Collapse>
        </Box>
    </Box>
  );
};

export default CollectionCard;

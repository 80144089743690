import React, { useState } from 'react';
import { Box, Typography, Collapse, IconButton, useMediaQuery } from '@mui/material';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import { faqData } from '../../data/FAQData';

const FAQ = () => {
    const isMobile = useMediaQuery('(max-width:1000px)');
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const toggleFAQ = (index: number) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        width: '100%',
        margin: '0 auto',
    }}>
      {faqData.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: '100%',
            border: '2px solid #ffffff',
            borderRadius: '32px',
            p: isMobile ? 2 : '2%',
            cursor: 'pointer',
            backgroundColor: openIndex === index ? '#333333' : 'transparent',
            transition: 'background-color 0.3s ease',
          }}
          onClick={() => toggleFAQ(index)}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            }}>
                <Typography
                variant="h6"
                sx={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: 'clamp(20px, 2.5vw, 28px)',
                }}
                >
                {item.question}
                </Typography>
                <IconButton
                sx={{
                    border: '2px solid #ffffff', // Белый бордер
                    borderRadius: '50%',
                    width: '48px',
                    height: '48px',
                    backgroundColor: 'transparent', // Прозрачный фон
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Лёгкий фон при наведении
                    },
                }}
                >
                {openIndex === index ? (
                    <SouthEastIcon sx={{ color: '#ffffff', fontSize: 'clamp(28px, 3vw, 32px)' }} />
                ) : (
                    <NorthEastIcon sx={{ color: '#ffffff', fontSize: 'clamp(28px, 3vw, 32px)' }} />
                )}
                </IconButton>
            </Box>
            <Collapse in={openIndex === index} timeout={300}>
                <Typography sx={{
                    color: '#cccccc',
                    fontSize: 'clamp(16px, 2vw, 20px)',
                    lineHeight: '1.5',
                    mt: 2,
                }}>
                    {item.answer}
                </Typography>
            </Collapse>
        </Box>
      ))}
    </Box>
  );
};

export default FAQ;

import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Fon1 from '../image/hero/hero1.jpg'
import Fon2 from '../image/hero/hero3.jpeg'

const About = () => {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const years = [2015, 2017, 2019, 2022, 2024];
  const activeYear = 2019;

  const displayedYears = isMobile
    ? years.filter((year) => Math.abs(year - activeYear) <= 3) 
    : years;

  return (
    <Box sx={{
        minHeight: 'calc(100vh - 140px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '32px',
        width: '100%',
        backgroundColor: '#000',
        color: '#fff',
    }}>
        <Box sx={{ flex: '0 0 auto', width: '100%' }}>
            <Typography
                variant="h1"
                sx={{
                    fontSize: 'clamp(48px, 7vw, 146px)',
                    fontWeight: 'bold',
                    color: '#ffffff',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                }}
            >
                ABOUT US
            </Typography>
        </Box>

        <Box sx={{
            flex: '0 0 auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
        }}>
            <Box sx={{
                width: '100%',
                height: '2px',
                backgroundColor: '#fff',
            }}/>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between', 
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}>
                {displayedYears.map((year) => {
                    let opacity = 1;
                    let color = '#ffffff';
                    if (year < activeYear) {
                        opacity = 0.3 + 0.15 * (year - 2015) / (activeYear - 2015);
                        color = `rgba(255, 255, 255, ${opacity})`;
                    } else if (year > activeYear) {
                        opacity = 0.3 + 0.15 * (2024 - year) / (2024 - activeYear);
                        color = `rgba(255, 255, 255, ${opacity})`;
                    }

                    return (
                    <Box key={year} sx={{
                        textAlign: 'center',
                        flex: '0 1 auto',
                    }}>
                        <Typography sx={{
                            fontSize: year === activeYear ? 'clamp(3.25rem, 5vw, 4.5rem)' : 'clamp(2.25rem, 4vw, 3.5rem)',
                            fontWeight: year === activeYear ? 'bold' : 'normal',
                            color: color,
                        }}>
                            {year}
                        </Typography>
                    </Box>
                    );
                })}
            </Box>

            <Box sx={{
                width: '100%',
                height: '2px',
                backgroundColor: '#fff',
            }}/>

        </Box>

        <Box sx={{
            flex: '1 1 auto',
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2,
            pt: 2
        }}>

            <Box sx={{
                flex: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}>
                <Box sx={{
                    flex: '0 0 auto',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', 
                    borderRadius: '16px',
                }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: 'clamp(1.5rem, 1.5vw, 2rem)',
                            fontWeight: 'bold',
                            color: '#ffffff',
                            textAlign: 'center',
                        }}
                    >
                        Milivnik is a place where everyone can find their unique style and become the best version of themselves. 
                        Our mission is to help you achieve this.
                    </Typography>
                </Box>

                <Box sx={{
                    flex: 1,
                    aspectRatio: '16 / 9',
                    backgroundImage: `url(${Fon1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '16px',
                }}/>
            </Box>


            <Box sx={{
                flex: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}>
                <Box sx={{
                    flex: 1,
                    aspectRatio: '16 / 9',
                    backgroundImage: `url(${Fon2})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '16px',
                }}/>

                <Box sx={{
                    flex: '0 0 auto',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', 
                    borderRadius: '16px',
                }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: 'clamp(1.5rem, 1.5vw, 2rem)',
                            fontWeight: 'bold',
                            color: '#ffffff',
                            textAlign: 'center',
                        }}
                    >
                        We care deeply about all our customers and products. 
                        We ensure quality and on-time delivery, always staying close to help when needed.
                    </Typography>
                </Box>
            </Box>

        </Box>

    </Box>
  );
};

export default About;

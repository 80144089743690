import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Fon from '../../image/hero/hero2.jpg';
import { heroData } from '../../data/HeroData';
import CustomButton from '../buttons/CustomButton';

const HomeSection = () => {
  const isMobile = useMediaQuery('(max-width:1000px)');

  const handleButtonClick = () => {
    console.log('Explore the Collection clicked');
  };
    
  return (
    <Box sx={{
      height: 'calc(100vh - clamp(120px, 10vw, 143px))', 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      p: 2,
      borderRadius: '32px',
      position: 'relative',
      overflow: 'hidden',
    }}>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${Fon})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: 'brightness(95%)',
        zIndex: 1,
      }}/>

      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        zIndex: 2,
      }}/>

      <Box sx={{
        zIndex: 3,
        textAlign: 'center',
        backdropFilter: 'blur(4px)',
        px: isMobile ? 1 : 2,
        py: isMobile ? 2 : 3,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '16px',
      }}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 'bold',
            fontSize: 'clamp(3rem, 5vw, 5rem)',
            color: '#ffffff',
            mb: 1,
          }}
        >
          Milivnik
        </Typography>

        <Box sx={{
          width: '100px',
          height: '4px',
          backgroundColor: '#ffffff',
          mx: 'auto',
          mb: 1,
        }}/>

        <Typography
          variant="h5"
          sx={{
            fontSize: 'clamp(1.5rem, 3vw, 2.5rem)',
            color: '#ffffff',
            fontWeight: '300',
          }}
        >
          Your Elegance in Every Moment
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        zIndex: 3
      }}>

        {!isMobile && 
          <Box sx={{
            display: 'flex',
            flex: 1,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Box sx={{
              display: 'flex',
              flex: 1,
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 4,
              maxWidth: '450px',
            }}>
              {heroData.map((item, index) => (
                <Box key={index} sx={{
                  width: '225px',
                  height: '150px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(8px)',
                  borderRadius: '16px',
                  color: '#ffffff',
                  textAlign: 'center',
                  p: 1,
                  alignSelf: index % 2 === 0 ? 'flex-start' : 'flex-end',
                  marginTop: index !== 0 ? '-30px' : '0',
                }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'clamp(1.5rem, 3vw, 2.1rem)',
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 'clamp(1rem, 2.5vw, 1.3rem)',
                    }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        }

        <Box sx={{
          display: 'flex',
          flex: 1,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'flex-end', 
          pb: 4, 
        }}>
          <CustomButton
            text="Explore the Collection"
            onClick={handleButtonClick}
            defaultColor="#fe4a23"
            hoverColor="#774dff"
            textColor="#ffffff"
          />
        </Box>
        
      </Box>

    </Box>
  );
};

export default HomeSection;

import React, { useState } from 'react';
import { Box, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Logo from '../../image/logo/darkThemeLogo.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { simpleFooterLinks } from '../../data/FooterData';
import { useNavigate } from 'react-router-dom';
import FlipTextFooter from '../buttons/FlipTextFooter';

const Footer: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:1000px)');

    const handleIconClick = (url: string) => {
        window.open(url, '_blank');
    };

    const handleLinkClick = (path: string) => {
        navigate(path);
    };
    

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            borderRadius: '32px',
            padding: '2%',
            backgroundColor: '#191919',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
        }}>
            {!isMobile && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <img
                                src={Logo}
                                alt="Logo"
                                style={{ height: '65px', width: 'auto' }}
                            />
                        </Box>

                        <Box sx={{ width: '75px', height: '4px', backgroundColor: 'white' }} />

                        {/* Иконки социальных сетей */}
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton onClick={() => handleIconClick('https://www.instagram.com')} sx={{ color: 'white' }}>
                                <InstagramIcon />
                            </IconButton>
                            <IconButton onClick={() => handleIconClick('https://www.facebook.com')} sx={{ color: 'white' }}>
                                <FacebookIcon />
                            </IconButton>
                            <IconButton onClick={() => handleIconClick('https://www.twitter.com')} sx={{ color: 'white' }}>
                                <XIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box>
                        <TextField
                            fullWidth
                            placeholder="Enter Your Email"
                            variant="outlined"
                            slotProps={{
                                input: {
                                    endAdornment:  <InputAdornment position="end"> <ArrowForwardIosIcon sx={{ color: '#ffffff' }} /> </InputAdornment>,
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ffffff', 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ffffff', 
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ffffff',
                                    },
                                },
                                borderRadius: '16px', 
                                fontSize: '24px',    
                                color: '#ffffff',     
                                '& input': {
                                    padding: '16px 20px', 
                                },
                                backgroundColor: 'transparent',
                            }}
                        />
                    </Box>
                </Box>
            )}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                flexDirection: 'row',
                padding: '24px 0',
                pb: isMobile ? '0' : '24px'
            }}>
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: 'clamp(48px, 8vw, 146px)',
                        fontWeight: 'bold',
                        color: '#ffffff',
                        letterSpacing: '2px',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                    }}
                >
                    LET'S TALK WITH US
                </Typography>
            </Box>

            {isMobile && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    mb: 2
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton onClick={() => handleIconClick('https://www.instagram.com')} sx={{ color: 'white' }}>
                                <InstagramIcon />
                            </IconButton>
                            <IconButton onClick={() => handleIconClick('https://www.facebook.com')} sx={{ color: 'white' }}>
                                <FacebookIcon />
                            </IconButton>
                            <IconButton onClick={() => handleIconClick('https://www.twitter.com')} sx={{ color: 'white' }}>
                                <XIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box>
                        <TextField
                            fullWidth
                            placeholder="Enter Your Email"
                            variant="outlined"
                            slotProps={{
                                input: {
                                    endAdornment:  <InputAdornment position="end"> <ArrowForwardIosIcon sx={{ color: '#ffffff' }} /> </InputAdornment>,
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ffffff', 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ffffff', 
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ffffff',
                                    },
                                },
                                borderRadius: '16px', 
                                fontSize: '24px',    
                                color: '#ffffff',     
                                '& input': {
                                    padding: '16px 20px', 
                                },
                                backgroundColor: 'transparent',
                            }}
                        />
                    </Box>
                </Box>
            )}

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                flexDirection: isMobile ? 'column-reverse' : 'row',
                paddingTop: '16px',
                borderTop: '1px solid #ffffff',
                gap: 1
            }}>
                <Typography sx={{ color: '#ffffff', fontSize: 'clamp(16px, 2vw, 22px)', }}>
                    Copyright 2024. Milivnik.com
                </Typography>

                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    {simpleFooterLinks.map((link) => (
                        <FlipTextFooter
                            key={link.name}
                            text={link.name}
                            onClick={() => handleLinkClick(link.path)}
                            isActive={window.location.pathname === link.path}
                        />
                    ))}
                </Box>

            </Box>
        </Box>
    );
};

export default Footer;

import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FonContact from '../image/contact/Contact.jpg'
import HoverIcon from '../components/buttons/HoverIcon';
import ContactForm from '../components/contact/ContactForm';

const Contact = () => {
    const isMobile = useMediaQuery('(max-width:1000px)');

    const handleIconClick = (url: string) => {
        window.open(url, '_blank');
    };

  return (
    <Box sx={{
        minHeight: 'calc(100vh - 140px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '32px',
        width: '100%',
    }}>
        <Box sx={{
            flex: '0 0 auto',
            width: '100%',
        }}>
            <Typography
                variant="h1"
                sx={{
                    fontSize: 'clamp(48px, 7vw, 146px)',
                    fontWeight: 'bold',
                    color: '#ffffff',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                }}
            >
                CONTACT US
            </Typography>
        </Box>

        <Box sx={{
            flex: '1 1 auto',
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2
        }}>
            <Box sx={{
                flex: isMobile ? 1 : '0.4',
                backgroundImage: `url(${FonContact})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: '16px',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                color: '#ffffff',
                overflow: 'hidden',
                gap: 1,
            }}>
                <Box sx={{
                    flex: '0 0 auto',
                    backdropFilter: 'blur(4px)',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    textAlign: 'center',
                }}>
                    <Typography variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: 'clamp(1.75rem, 2.5vw, 2.5rem)',
                        }}
                    >
                        Contact Us
                    </Typography>
                    <Typography variant="body1"
                        sx={{
                            fontSize: 'clamp(1.5rem, 2vw, 2rem)',
                            fontWeight: '400',
                        }}
                    >
                        We are here to help you
                    </Typography>
                </Box>

                <Box sx={{
                    flex: '1 1 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: 1,
                }}>
                    <Box sx={{
                        border: '2px solid #ccc',
                        backdropFilter: 'blur(2px)',
                        backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        borderRadius: '16px',
                        p: 1,
                        textAlign: 'center',
                        width: '100%',
                    }}>
                        <Typography variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: 'clamp(1.25rem, 2vw, 1.5rem)',
                                color: 'black'
                            }}
                        >
                            Visit Us
                        </Typography>
                        <Typography variant="body1"
                            sx={{
                                fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                                color: '#333',
                            }}
                        >
                            123 Main Street, Cityville, Country
                        </Typography>
                    </Box>

                    <Box sx={{
                        border: '2px solid #ccc',
                        backdropFilter: 'blur(2px)',
                        backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        borderRadius: '16px',
                        p: 1,
                        textAlign: 'center',
                        width: '100%',
                    }}>
                        <Typography variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: 'clamp(1.25rem, 2vw, 1.5rem)',
                                color: 'black'
                            }}
                        >
                            Call Us
                        </Typography>
                        <Typography variant="body1"
                            sx={{
                                fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                                color: '#333',
                            }}
                        >
                            +1 (555) 123-4567
                        </Typography>
                    </Box>

                    <Box sx={{
                        border: '2px solid #ccc',
                        backdropFilter: 'blur(2px)',
                        backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        borderRadius: '16px',
                        p: 1,
                        textAlign: 'center',
                        width: '100%',
                    }}>
                        <Typography variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: 'clamp(1.25rem, 2vw, 1.5rem)',
                                color: 'black'
                            }}
                        >
                         Chat to Us
                        </Typography>
                        <Typography variant="body1"
                            sx={{
                                fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                                color: '#333',
                            }}
                        >
                            support@example.com
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{
                    flex: '0 0 auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    gap: 2
                }}>

                    <HoverIcon icon={<InstagramIcon />} onClick={() => handleIconClick('https://instagram.com')} />
                    <HoverIcon icon={<FacebookIcon />} onClick={() => handleIconClick('https://facebook.com')} />
                    <HoverIcon icon={<LinkedInIcon />} onClick={() => handleIconClick('https://linkedin.com')} />

                </Box>

            </Box>

            <Box sx={{
                flex: isMobile ? 1 : '0.6',
                backgroundColor: '#ffffff',
                borderRadius: '16px',
                p: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <ContactForm />
            </Box>
        </Box>
    </Box>
  );
};

export default Contact;

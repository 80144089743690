import React from 'react';
import { Box, Typography } from '@mui/material';
import { popularProducts, mostPopularCollection } from '../../data/MostPopularData';
import MostPopularCollectionCard from '../cards/MostPopularCollectionCard';
import PopularProductCard from '../cards/PopularProductCard';
import Grid from '@mui/material/Grid2';

const MostPopular = () => {
  return (
    <Box sx={{
        minHeight: 'calc(100vh - 140px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }}>
      <Typography
        variant="h1"
        sx={{
          mb: 3,
          fontWeight: 'bold',
          fontSize: 'clamp(36px, 6vw, 72px)',
          textAlign: 'center',
        }}
      >
        Most Popular
      </Typography>

      <Grid container spacing={2} sx={{ display: 'flex', height: '100%', width: '100%' }}>
        <Grid size={{ xs: 12, sm: 12, md: 3 }} sx={{ display: 'flex', minHeight: '100%',  flexDirection: "column", gap: 2,  width: '100%', flexGrow: 1, justifyContent: 'center', }}>
          {popularProducts.slice(0, 2).map((product, index) => (
            <PopularProductCard
              key={index}
              title={product.title}
              image={product.image}
              description={product.description}
              price={product.price}
            />
          ))}
        </Grid>

        <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', minHeight: '100%',  justifyContent: 'center', width: '100%', flexGrow: 1 }}>
          <MostPopularCollectionCard
            title={mostPopularCollection.title}
            image={mostPopularCollection.image}
            description={mostPopularCollection.description}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 12, md: 3 }} sx={{ display: 'flex', minHeight: '100%',  flexDirection: "column", gap: 2,  width: '100%', flexGrow: 1, justifyContent: 'center', }}>
          {popularProducts.slice(2, 4).map((product, index) => (
            <PopularProductCard
              key={index}
              title={product.title}
              image={product.image}
              description={product.description}
              price={product.price}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MostPopular;

import Product1 from '../image/newproducts/Product1.jpg';
import Product2 from '../image/newproducts/Product2.jpeg';
import Product3 from '../image/newproducts/Product3.jpeg';

import CollectionImage from '../image/collections/Autumn2.jpg';

export interface ProductItem {
  image: string;
  title: string;
  description: string;
  price: string;
}

export interface CollectionItem {
  title: string;
  image: string;
  description: string;
}

export const popularProducts: ProductItem[] = [
  {
    image: Product1,
    title: 'Trendy Jacket',
    description: 'Stylish jacket perfect for winter.',
    price: '$120.00',
  },
  {
    image: Product2,
    title: 'Classic Sneakers',
    description: 'Comfortable sneakers for everyday wear.',
    price: '$85.00',
  },
  {
    image: Product3,
    title: 'Elegant Watch',
    description: 'A sleek watch to complement your outfit.',
    price: '$250.00',
  },
  {
    image: Product3,
    title: 'Elegant Watch',
    description: 'A sleek watch to complement your outfit.',
    price: '$250.00',
  },
];

export const mostPopularCollection: CollectionItem = {
  title: 'Autumn Essentials',
  image: CollectionImage,
  description: 'A collection of must-have Autumn styles.',
};

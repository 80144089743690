import Product1 from '../image/newproducts/Product1.jpg';
import Product2 from '../image/newproducts/Product2.jpeg';
import Product3 from '../image/newproducts/Product3.jpeg';
import Product4 from '../image/newproducts/Product4.jpg';
import Product5 from '../image/newproducts/Product5.jpg';
import Product6 from '../image/newproducts/Product6.jpeg';
import Product7 from '../image/newproducts/Product7.jpeg';

export interface ProductItem {
  image: string;
  title: string;
  description: string;
  price: string;
}

export const newProductsData: ProductItem[] = [
  {
    image: Product1,
    title: 'Stylish Jacket',
    description: 'A trendy jacket perfect for winter seasons.',
    price: '$120.00',
  },
  {
    image: Product2,
    title: 'Casual Sneakers',
    description: 'Comfortable sneakers for everyday wear.',
    price: '$85.00',
  },
  {
    image: Product3,
    title: 'Elegant Watch',
    description: 'A classic watch that complements any outfit.',
    price: '$250.00',
  },
  {
    image: Product4,
    title: 'Leather Handbag',
    description: 'Spacious and stylish leather handbag.',
    price: '$150.00',
  },
  {
    image: Product5,
    title: 'Denim Jeans',
    description: 'High-quality denim jeans with a modern fit.',
    price: '$60.00',
  },
  {
    image: Product6,
    title: 'Graphic T-Shirt',
    description: 'Soft cotton T-shirt with a stylish graphic design.',
    price: '$30.00',
  },
  {
    image: Product7,
    title: 'Running Shoes',
    description: 'Lightweight and durable running shoes for sports.',
    price: '$90.00',
  },
];

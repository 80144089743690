import { styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: '#ffffff',  
    fontWeight: 'bold',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#999999', 
    },
    '&:hover fieldset': {
      borderColor: '#cccccc',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white', 
    },
  },
  '& .MuiInputLabel-root': {
    color: '#999999',  
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'white',  
  },
}));

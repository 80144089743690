import Summer from '../image/collections/Summer2.jpg';
import Winter from '../image/collections/Winter.jpg';
import Autumn from '../image/collections/Autumn2.jpg';
import Spring from '../image/collections/Spring.jpg';

export interface CollectionItem {
    title: string;
    image: string;
    description: string;
  }
  
  export const newCollectionsData: CollectionItem[] = [
    {
      title: 'Summer Vibes',
      image: Summer,
      description: 'Bright and breezy summer collection perfect for warm days.',
    },
    {
      title: 'Autumn Elegance',
      image: Autumn,
      description: 'Elegant styles inspired by the rich colors of autumn.',
    },
    {
      title: 'Winter Comfort',
      image: Winter,
      description: 'Cozy and warm outfits to keep you comfortable this winter.',
    },
    {
      title: 'Spring Revival',
      image: Spring,
      description: 'Fresh and vibrant styles to celebrate the renewal of spring.',
    },
  ];
  
import React from 'react';
import { Box, Button, IconButton, useMediaQuery } from '@mui/material';
import Logo from '../../image/logo/darkThemeLogo.png';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import FlipTextLink from '../buttons/FlipTextLink';
import MobileMenu from './MobileMenu';
import CustomButton from '../buttons/CustomButton';
import AccountButton from './AccountButton';
import CartButton from './CartButton';

const iconActions = [
  { icon: <SearchIcon />, path: '/search' },
  { icon: <ShoppingCartIcon />, path: '/cart' },
  { icon: <FavoriteIcon />, path: '/favorites' },
];

const pages = [
  { name: 'Home', path: '/' },
  { name: 'Shop', path: '/shop' },
  { name: 'About', path: '/about' },
  { name: 'Contact Us', path: '/contact' },
];

interface NavBarProps {
  theme: 'light' | 'dark';
  onThemeChange: (theme: 'light' | 'dark') => void;
}

const NavBar: React.FC<NavBarProps> = ({ theme, onThemeChange }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:1000px)');

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Box sx={{
      width: '100%',
      height: 'clamp(85px, 10vw, 95px)',
      padding: 2,
      backgroundColor: '#191919',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '16px',
      color: 'white',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
    }}>
      {!isMobile && (
        <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
          {pages.map((page) => (
            <FlipTextLink
              key={page.name}
              text={page.name}
              onClick={() => handleNavigation(page.path)}
              isActive={window.location.pathname === page.path}
            />
          ))}
        </Box>
      )}

      <Box onClick={() => handleNavigation('/')} sx={{ display: 'flex', justifyContent: 'center', flexShrink: 0, cursor: 'pointer' }}>
        <img
          src={Logo}
          alt="Logo"
          style={{ height: 'clamp(50px, 8vw, 65px)', width: 'auto' }}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 1, flex: 1, justifyContent: 'flex-end' }}>
        {!isMobile ? (
          <>
          <CartButton/>
          <AccountButton/>
        </>
        ) : (
          <MobileMenu 
            pages={pages} 
            onNavigate={handleNavigation} 
            theme={theme} 
            onThemeChange={onThemeChange} 
          />        
        )}
      </Box>

    </Box>
  );
};

export default NavBar;

import React, { useState } from 'react';
import { Box, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { lightTheme, darkTheme } from '../utils/theme';
import Home from './Home';
import Authentication from './Authentication';
import ScrollToTopButton from '../utils/ScrollToTopButton';
import NavBar from '../components/navbar/NavBar';
import Footer from '../components/footer/Footer';
import Shop from './Shop';
import About from './About';
import Contact from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import ScrollToTop from '../utils/ScrollToTop';

const Main = () => {
  const [theme, setTheme] = useState(darkTheme);
  const isMobile = useMediaQuery('(max-width:1000px)');

  const handleThemeChange = (newTheme: 'light' | 'dark') => {
    setTheme(newTheme === 'light' ? lightTheme : darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        
        <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1000, p: 2 }}>
          <NavBar theme={theme === lightTheme ? 'light' : 'dark'} onThemeChange={handleThemeChange} />
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          width: '100%',
          p: 2,
          py: 0,
          minHeight: '80vh',
          mt: 'clamp(117px, 10vw, 127px)', 
        }}>          
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="Shop" element={<Shop />} />
            <Route path="About" element={<About />} />
            <Route path="Contact" element={<Contact />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="Authentication" element={<Authentication />} />
          </Routes>
        </Box>

        <Box sx={{
          p: 2,
          mt: 1
        }}>
          <Footer />
        </Box>
        <ScrollToTopButton />
      </Box>
    </ThemeProvider>
  );
};

export default Main;

import React from 'react';
import { Box, Typography } from '@mui/material';

interface FlipTextLinkProps {
  text: string;
  onClick: () => void;
  isActive: boolean;
}

const FlipTextFooter: React.FC<FlipTextLinkProps> = ({ text, onClick, isActive }) => {
  return (
    <Box onClick={onClick} sx={{
        position: 'relative',
        display: 'inline-flex', 
        textDecoration: 'none',
        height: '18px',
        cursor: 'pointer',
        perspective: '2500px',
        '&:hover > div, &.active > div': {
        transform: 'rotateX(-90deg)',
        },
    }}
    className={isActive ? 'active' : ''}
    >
        <Box sx={{
            position: 'relative',
            width: 'fit-content', 
            height: '100%',
            transformStyle: 'preserve-3d',
            transition: 'transform 0.3s ease-in-out',
        }}>
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#999999',
                fontWeight: 'bold',
                fontSize: 'clamp(16px, 2vw, 22px)',
                backgroundColor: '#191919',
                backfaceVisibility: 'hidden',
                transform: 'rotateX(0deg)',
                whiteSpace: 'nowrap', 
            }}>
                {text}
            </Box>
    
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#ffffff',
                fontWeight: 'bold',
                fontSize: 'clamp(16px, 2vw, 22px)',
                backgroundColor: '#191919',
                backfaceVisibility: 'hidden',
                transform: 'rotateX(90deg)',
                whiteSpace: 'nowrap', 
            }}>
                {text}
            </Box>
        </Box>
    </Box>
  );
};

export default FlipTextFooter;

import React, { useState } from 'react';
import { Box, Button, Divider, IconButton, InputAdornment, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import { StyledTextField } from '../../styled/StyledTextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomButton from '../buttons/CustomButton';

interface LoginProps {
  onSwitch: () => void;
}

const Login: React.FC<LoginProps> = ({ onSwitch }) => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let error = '';

    if (name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      error = 'Te rog introdu un email valid.';
    }

    if (name === 'password' && value.length < 6) {
      error = 'Parola trebuie să aibă cel puțin 6 caractere.';
    }

    setErrors({ ...errors, [name]: error });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = () => {
    // Validate form
    let isValid = true;
    const newErrors = { email: '', password: '' };

    if (!formData.email) {
      newErrors.email = 'Te rog introdu un email.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Te rog introdu un email valid.';
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = 'Te rog introdu parola.';
      isValid = false;
    } else if (formData.password.length < 6) {
      newErrors.password = 'Parola trebuie să aibă cel puțin 6 caractere.';
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      // Submit the form
      console.log('Form data submitted:', formData);
    }
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '16px',
      gap: 4
    }}>
      <Box sx={{
        display: 'flex',
        flex: '0 0 auto',
        maxWidth: '600px',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2
      }}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: 'clamp(1.5rem, 2.5vw, 2.4rem)', 
          }}
        >
          Intra in contul tau
        </Typography>

        <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1
        }}>
          <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#999999' }}>
            Nu ai cont?
          </Typography>
          <CustomButton
            text='Register'
            onClick={onSwitch}
            defaultColor="#ffffff"
            hoverColor="#CCCCCC"
            textColor="#000000"
            fontSize = 'clamp(1rem, 2vw, 1rem)'
            maxWidth='120px'
          />
        </Box>

        <Box sx={{
          display: 'flex',
          flex: '0 0 auto',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2
        }}>
          <StyledTextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            required
          />

          <StyledTextField
            label="Parola"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleInputChange}
            onBlur={handleBlur}
            error={!!errors.password}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />

          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{
              backgroundColor: 'white', 
              color: 'black',
              borderRadius: '8px',
              textTransform: 'none',
              padding: '12px 24px',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)', 
                color: 'rgba(0, 0, 0, 0.85)', 
                transform: 'scale(1.025)',
              },
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', 
              transition: 'all 0.3s ease',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              Intra
            </Typography>
          </Button>


          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: 1,
          }}>
            <Divider sx={{ flex: 1, height: '2px', backgroundColor: '#999999' }} />
            <Typography variant='h6' sx={{ mx: 2, color: '#999999' }}>
              Or login with
            </Typography>
            <Divider sx={{ flex: 1, height: '2px', backgroundColor: '#999999' }} />
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}>
            <Box sx={{
              flex: 1,
              minWidth: '250px',
              borderColor: '#ccc',
              borderRadius: '8px',
              color: 'white',
              textTransform: 'none',
              padding: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              transition: 'all 0.3s ease',
              cursor: 'pointer',
              '&:hover': {
                borderColor: '#999999',
                transform: 'translateY(-2px)',
              },
            }}
              onClick={() => { /* Handle Google click event */ }}
            >
              <GoogleIcon sx={{ mr: 1 }} />
              <Typography variant='h6'>
                Google
              </Typography>
            </Box>

            <Box sx={{
              flex: 1,
              minWidth: '250px',
              borderColor: '#ccc',
              borderRadius: '8px',
              color: 'white',
              textTransform: 'none',
              padding: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              transition: 'all 0.3s ease',
              cursor: 'pointer',
              '&:hover': {
                borderColor: '#999999',
                transform: 'translateY(-2px)',
              },
            }}
              onClick={() => { /* Handle Instagram click event */ }}
            >
              <InstagramIcon sx={{ mr: 1 }} />
              <Typography variant='h6'>
                Instagram
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;

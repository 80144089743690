import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

interface NewProductCardProps {
  image: string;
  title: string;
  description: string;
  price: string;
}

const NewProductCard: React.FC<NewProductCardProps> = ({ image, title, description, price }) => {
  return (
    <Box sx={{
        width: '100%',
        height: '100%',
        maxWidth: 'clamp(350px, 25vw, 450px)',
        borderRadius: '16px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        p: 1,
        backgroundColor: '#f2f2f2',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    }}>
        <Box sx={{
            width: '100%',
            borderRadius: '16px',
            paddingTop: '100%',
            position: 'relative',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <Box sx={{
                position: 'absolute',
                top: 8,
                left: 8,
                backgroundColor: 'rgba(255, 255, 255, 0.2)', 
                backdropFilter: 'blur(8px)',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 60,
                height: 60,
            }}>
                <FavoriteBorderOutlinedIcon fontSize='small' sx={{ color: '#ffffff' }} />
            </Box>
        </Box>

        <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
            <Typography
                variant="h3"
                sx={{
                    fontWeight: 'bold',
                    fontSize: 'clamp(1.75rem, 3vw, 2.25rem)', 
                    color: '#000000',                   
                }}
            >
                {title}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    fontSize: 'clamp(1rem, 2vw, 1.325rem)', 
                    color: '#777777',     
                    fontWeight: 'bold',              
                }}
            >
                {description}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    fontSize: 'clamp(1.5rem, 2vw, 1.75rem)', 
                    color: '#000000',     
                    fontWeight: 'bold',              
                }}
            >
                {price}
            </Typography>
        </Box>

        <Box sx={{
          position: 'absolute',
          bottom: 4,
          right: 4,
        }}>
            <IconButton sx={{
                backgroundColor: '#000000',
                color: '#ffffff',
                borderRadius: '50%',
                width: 60,
                height: 60,
                '&:hover': {
                backgroundColor: '#333333',
                },
            }}>
                <EastIcon />
            </IconButton>
        </Box>
        
    </Box>
  );
};

export default NewProductCard;

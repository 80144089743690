import React from 'react';
import { Box, Typography } from '@mui/material';
import { advantagesData } from '../../data/AdvantagesData';
import Grid from '@mui/material/Grid2';

const AdvantagesSection: React.FC = () => {
  return (
    <Box>
        <Grid container spacing={4} justifyContent="center">
            {advantagesData.map((advantage, index) => (
                <Grid key={index} size={{ xs: 12, sm: 6, md: 4 }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        aspectRatio: '1 / 1',
                        borderRadius: '32px',
                        backgroundColor: '#191919',
                        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)', 
                        p: '6%', 
                        textAlign: 'center',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease', 
                        '&:hover': {
                        transform: 'translateY(-10px)', 
                        boxShadow: '0px 12px 32px rgba(0, 0, 0, 0.3)', 
                        },
                    }}>
                        <advantage.icon
                            sx={{
                                fontSize: 'clamp(80px, 8vw, 110px)', 
                                color: '#ffffff',
                                mb: 3,
                            }}
                        />
                        <Typography
                            variant="h5" 
                            sx={{
                                fontWeight: 'bold',
                                fontSize: 'clamp(32px, 3vw, 42px)', 
                                color: '#ffffff',
                                mb: 2, 
                            }}
                        >
                            {advantage.title}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#cccccc', 
                                fontSize: 'clamp(20px, 2.5vw, 28px)', 
                                lineHeight: '1.6',
                            }}
                        >
                            {advantage.text}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    </Box>
  );
};

export default AdvantagesSection;

import React, { useState } from 'react'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { newProductsData } from '../../data/NewProductsData'
import NewProductCard from '../cards/NewProductCard'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const NewProducts = () => {
    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const isMediumScreen = useMediaQuery('(max-width:1300px)');
    const VISIBLE_CARDS = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;
    const CARD_WIDTH_PERCENTAGE = 100 / VISIBLE_CARDS;
    const GAP_PERCENTAGE = 1; 
    const PADDING_PIXELS = 8; 
    const [currentIndex, setCurrentIndex] = useState(0);
    const isPrevDisabled = currentIndex === 0;
    const isNextDisabled = currentIndex >= newProductsData.length - VISIBLE_CARDS;
    const totalIndicators = Math.ceil(newProductsData.length / VISIBLE_CARDS);

    const handleNext = () => {
        const remainingItems = newProductsData.length - currentIndex - VISIBLE_CARDS;
        setCurrentIndex((prevIndex) => prevIndex + Math.min(VISIBLE_CARDS, remainingItems));
    };
      
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => prevIndex - Math.min(VISIBLE_CARDS, prevIndex));
    };
      
    
    
  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }}>

        <Typography
            variant="h1"
            sx={{
                mb: 0.5,
                fontWeight: 'bold',
                fontSize: 'clamp(36px, 6vw, 72px)',
                textAlign: 'center',
            }}
        >
            Latest Products
        </Typography>

        <Box sx={{
            flex: '1 1 auto',
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            overflow: 'hidden',
            position: 'relative',
            justifyContent: 'center',
        }}>

            <IconButton
            onClick={handlePrev}
            disabled={isPrevDisabled}
            sx={{
                position: 'absolute',
                left: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
            }}
            >
            <ArrowBackIosNewIcon sx={{ color: 'white', fontSize: '2rem' }} />
            </IconButton>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                transform: `translateX(-${currentIndex * (CARD_WIDTH_PERCENTAGE + GAP_PERCENTAGE / VISIBLE_CARDS)}%)`,
                transition: 'transform 0.5s ease-in-out',
                width: `100%`,
                gap: `${GAP_PERCENTAGE}%`,
            }}>
                {newProductsData.map((product, index) => (
                    <Box 
                        key={index} 
                        sx={{ 
                        flex: `0 0 ${isSmallScreen ? CARD_WIDTH_PERCENTAGE + '%' : `calc(${CARD_WIDTH_PERCENTAGE}% - 0.75%)`}`,
                        display: 'flex',
                        justifyContent: 'center',
                        boxSizing: 'border-box',
                    }}>
                        <NewProductCard
                            image={product.image}
                            title={product.title}
                            description={product.description}
                            price={product.price}
                        />
                    </Box>
                ))}
            </Box>

            <IconButton
            onClick={handleNext}
            disabled={isNextDisabled}
            sx={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
            }}
            >
            <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '2rem' }} />
            </IconButton>

        </Box>

        <Box sx={{
            display: 'flex',
            flex: '0 0 auto',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 1
        }}>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 1,
                    flexWrap: 'wrap', // Позволяет элементам переноситься на новую строку
                    maxWidth: '100%', // Ограничивает ширину контейнера
                    overflow: 'hidden', // Предотвращает выход элементов за границы
                }}
                >
                {Array.from({ length: totalIndicators }).map((_, index) => {
                    // Рассчитываем первый элемент для текущей страницы
                    const startIndex = index * VISIBLE_CARDS;
                    const endIndex = startIndex + VISIBLE_CARDS;

                    // Проверка для активации индикатора
                    const isActive = currentIndex >= startIndex && currentIndex < endIndex;

                    // Специальная проверка для последней страницы
                    const isLastPage = index === totalIndicators - 1 && currentIndex + VISIBLE_CARDS >= newProductsData.length;

                    return (
                    <Box
                        key={index}
                        sx={{
                        width: '50px',
                        height: '6px',
                        backgroundColor: isActive || isLastPage ? 'white' : 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '3px',
                        transition: 'background-color 0.3s ease',
                        }}
                    />
                    );
                })}
            </Box>

        </Box>
    
    </Box>
  )
}

export default NewProducts
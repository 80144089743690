import React from 'react';
import { Box, Typography } from '@mui/material';

interface MostPopularCollectionCardProps {
  title: string;
  image: string;
  description: string;
}

const MostPopularCollectionCard: React.FC<MostPopularCollectionCardProps> = ({ title, image }) => {
  return (
    <Box sx={{
        width: '100%',
        maxWidth: '800px',
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        m: 'auto',
        cursor: 'pointer', 
        transition: 'transform 0.3s ease, box-shadow 0.3s ease', 
        '&:hover': {
          transform: 'translateY(-8px)', 
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)', 
        },
    }}>
        <Box sx={{
             width: '100%',
            paddingTop: '100%',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            borderRadius: '16px',
        }}/>

        <Box sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            right: 16,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(8px)',
            borderRadius: '12px',
            padding: '8px 16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Typography
            variant="h5"
                sx={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: 'clamp(2rem, 3vw, 3rem)',
                    textAlign: 'center',
                }}
            >
                {title}
            </Typography>
      </Box>
    </Box>
  );
};

export default MostPopularCollectionCard;

import React, { useState } from 'react';
import { Box } from '@mui/material';

interface HoverIconProps {
  icon: React.ReactNode;
  onClick: () => void;
  color?: string;
  hoverColor?: string;
}

const HoverIcon: React.FC<HoverIconProps> = ({ icon, onClick, color = '#ffffff', hoverColor = '#fe4a23' }) => {
  const [hoverPosition, setHoverPosition] = useState({ x: '50%', y: '50%' });
  const [isHovered, setIsHovered] = useState(false);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setHoverPosition({ x: `${x}px`, y: `${y}px` });

    const offsetX = (x - rect.width / 2) * 0.075;
    const offsetY = (y - rect.height / 2) * 0.075;
    setTranslate({ x: offsetX, y: offsetY });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setTranslate({ x: 0, y: 0 });
  };

  return (
    <Box
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      sx={{
        position: 'relative',
        backgroundColor: '#3b3a38',
        borderRadius: '16px',
        padding: '16px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: color,
        fontSize: '24px',
        overflow: 'hidden',
        width: '56px',
        height: '56px',
        transform: `translate(${translate.x}px, ${translate.y}px)`,
        transition: 'transform 0.1s ease-out',
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '16px',
        '&::before': {
          content: '""',
          position: 'absolute',
          width: '300%',
          height: '300%',
          backgroundColor: hoverColor,
          borderRadius: '50%',
          top: `calc(${hoverPosition.y} - 150%)`,
          left: `calc(${hoverPosition.x} - 150%)`,
          transform: isHovered ? 'scale(1)' : 'scale(0)',
          transition: 'transform 0.8s ease',
          zIndex: 0,
        },
      }}/>
      <Box sx={{ position: 'relative', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {icon}
      </Box>
    </Box>
  );
};

export default HoverIcon;

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Fon1 from '../../image/authentication/fon1.jpg';
import Fon2 from '../../image/authentication/fon2.jpg';
import Fon3 from '../../image/authentication/fon3.jpg';

const imageList = [Fon1, Fon2, Fon3];

const AuthenticationCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, 5000); 

    return () => clearInterval(interval); 
  }, []);

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '16px',
    }}>
      <Box sx={{
        display: 'flex',
        width: `${imageList.length * 100}%`, 
        height: '100%',
        transform: `translateX(-${activeIndex * (100 / imageList.length)}%)`, 
        transition: 'transform 1s ease-in-out',
      }}>
        {imageList.map((image, index) => (
          <Box
            key={index}
            sx={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              flex: '1', 
              aspectRatio: '16 / 9',
            }}
          />
        ))}
      </Box>

      <Box sx={{
        position: 'absolute',
        bottom: 16,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        gap: '8px', 
      }}>
        {imageList.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: '50px',
              height: '6px', 
              backgroundColor: index === activeIndex ? 'white' : 'rgba(255, 255, 255, 0.5)', 
              borderRadius: '3px', 
              transition: 'opacity 0.3s ease', 
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default AuthenticationCarousel;

import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export interface HeroStat {
  icon: React.ReactElement;
  title: string;
  text: string;
}

export const heroData: HeroStat[] = [
  {
    icon: <StarOutlineIcon />,
    title: '5+',
    text: 'We have 5 years of experience',
  },
  {
    icon: <ShoppingCartIcon />,
    title: '2M',
    text: 'Products sold in 5 years',
  },
  {
    icon: <PeopleOutlineIcon />,
    title: '4K',
    text: 'Active users',
  },
  
];

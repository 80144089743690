import React from 'react';
import { Box, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material';
import Fon from '../../image/subscription/SubscribeFon.jpg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const SubscriptionSection: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box  sx={{
      height: 'calc(100vh - 140px)',
      display: 'grid',
      gridTemplateColumns: isMobile ? '1f' : '1fr 1fr',
      gap: '2%',
      p: isMobile ? 2 : '2%',
      borderRadius: '32px',
      backgroundImage: `url(${Fon})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      overflow: 'hidden',
      position: 'relative',
    }}>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '32px',
        zIndex: 1,
      }}/>

      <Box sx={{ 
        zIndex: 2, 
        display: 'flex', 
        alignItems: 'flex-start', 
        justifyContent: 'flex-start',
        height: '100%', 
        width: '100%', 
      }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: 'clamp(64px, 8vw, 146px)',
            fontWeight: 'bold',
            color: '#ffffff',
            letterSpacing: '2px',
            textTransform: 'uppercase',
            textAlign: 'left',
            lineHeight: '1.1',
            textShadow: '2px 2px 6px rgba(0, 0, 0, 0.7)',
            whiteSpace: 'normal',
          }}
        >
          LET'S TALK <br /> WITH US
        </Typography>
      </Box>

      <Box sx={{
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: '#ffffff',
          borderRadius: '16px',
          p: 2,
          maxWidth: '500px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: '#191919',
              textAlign: 'left',
              fontSize: 'clamp(20px, 2vw, 32px)', 
            }}
          >
            Subscribe to Our Newsletter for More Information
          </Typography>

          <Typography
            sx={{
              color: '#555555',
              textAlign: 'left',
              fontSize: 'clamp(16px, 1.5vw, 24px)',
              lineHeight: '1.6', 
            }}
          >
            Get all updates about our store. Just subscribe and get all news instantly.
          </Typography>
          <TextField
            fullWidth
            placeholder="Enter Your Email"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowForwardIosIcon sx={{ color: '#191919' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: '#191919', 
                fontSize: 'clamp(16px, 1.5vw, 22px)', 
                '& fieldset': {
                  borderColor: '#cccccc',
                  borderRadius: '16px',
                },
                '&:hover fieldset': {
                  borderColor: '#aaaaaa',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#191919',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#1a1a1a',
                fontSize: 'clamp(16px, 1.5vw, 22px)', 
              },
              '& input': {
                padding: '12px 16px',
              },
            }}
          />


        </Box>
      </Box>
    </Box>
  );
};

export default SubscriptionSection;

export interface FAQItem {
    question: string;
    answer: string;
  }
  
  export const faqData: FAQItem[] = [
    {
      question: 'How can I place an order?',
      answer: 'To place an order, add items to your cart and proceed to checkout by following the on-screen instructions.',
    },
    {
      question: 'How can I track my order?',
      answer: 'After your order has been shipped, you will receive a tracking number via email.',
    },
    {
      question: 'How can I return an item?',
      answer: 'You can return an item within 14 days of receiving it. Please contact our support team for return instructions.',
    },
    {
      question: 'What payment methods do you accept?',
      answer: 'We accept Visa, MasterCard, PayPal, and other popular payment methods.',
    },
  ];
  
import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import CustomButton from '../buttons/CustomButton';

const ContactForm = () => {

    const handleSend = () => {
        console.log('Message Sent');
    };

  return (
    <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '100%',
            height: '100%',
            flex: '1 1 auto',
            gap: 1,
        }}>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', width: '100%', }}>
                <Box sx={{ flex: 1, minWidth: '250px' }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                            fontWeight: 'bold',
                            color: '#000',
                        }}
                    >
                        First Name
                    </Typography>
                    <TextField
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        sx: { color: '#555' }, // Темно-серый цвет для label
                    }}
                    InputProps={{
                        sx: {
                        color: '#000',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        '& fieldset': { borderColor: '#ccc' },
                        '&:hover fieldset': { borderColor: '#bb86fc !important' },
                        '&.Mui-focused fieldset': { borderColor: '#bb86fc !important' },
                        },
                    }}
                    />
                </Box>

                <Box sx={{ flex: 1, minWidth: '250px' }}>
                    <Typography
                    variant="body1"
                    sx={{
                        fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                        fontWeight: 'bold',
                        color: '#000',
                    }}
                    >
                    Last Name
                    </Typography>
                    <TextField
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        sx: { color: '#555' }, // Темно-серый цвет для label
                    }}
                    InputProps={{
                        sx: {
                        color: '#000',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        '& fieldset': { borderColor: '#ccc' },
                        '&:hover fieldset': { borderColor: '#bb86fc !important' },
                        '&.Mui-focused fieldset': { borderColor: '#bb86fc !important' },
                        },
                    }}
                    />
                </Box>
            </Box>

            <Box>
            <Typography
                variant="body1"
                sx={{
                fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                fontWeight: 'bold',
                color: '#000',
                }}
            >
                Email
            </Typography>
            <TextField
                fullWidth
                variant="outlined"
                InputLabelProps={{
                sx: { color: '#555' }, // Темно-серый цвет для label
                }}
                InputProps={{
                sx: {
                    color: '#000',
                    borderRadius: '8px',
                    backgroundColor: '#ffffff',
                    '& fieldset': { borderColor: '#ccc' },
                    '&:hover fieldset': { borderColor: '#bb86fc !important' },
                    '&.Mui-focused fieldset': { borderColor: '#bb86fc !important' },
                },
                }}
            />
            </Box>

            <Box>
            <Typography
                variant="body1"
                sx={{
                fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                fontWeight: 'bold',
                color: '#000',
                }}
            >
                Number
            </Typography>
            <TextField
                fullWidth
                variant="outlined"
                InputLabelProps={{
                sx: { color: '#555' }, // Темно-серый цвет для label
                }}
                InputProps={{
                sx: {
                    color: '#000',
                    borderRadius: '8px',
                    backgroundColor: '#ffffff',
                    '& fieldset': { borderColor: '#ccc' },
                    '&:hover fieldset': { borderColor: '#bb86fc !important' },
                    '&.Mui-focused fieldset': { borderColor: '#bb86fc !important' },
                },
                }}
            />
            </Box>

            <Box>
            <Typography
                variant="body1"
                sx={{
                fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
                fontWeight: 'bold',
                color: '#000',
                }}
            >
                Message
            </Typography>
            <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                InputLabelProps={{
                sx: { color: '#555' }, // Темно-серый цвет для label
                }}
                InputProps={{
                sx: {
                    color: '#000',
                    borderRadius: '8px',
                    backgroundColor: '#ffffff',
                    '& fieldset': { borderColor: '#ccc' },
                    '&:hover fieldset': { borderColor: '#bb86fc !important' },
                    '&.Mui-focused fieldset': { borderColor: '#bb86fc !important' },
                },
                }}
            />
            </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flex: '0 0 auto',
          mt: 1
        }}>
            <CustomButton
                text="Send Message"
                onClick={handleSend}
                defaultColor="#fe4a23"
                hoverColor="#774dff"
                textColor="#ffffff"
                fontSize="clamp(1rem, 1.5vw, 1.75rem)"
            />
        </Box>
    </Box>
  );
};

export default ContactForm;

import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import AuthenticationCarousel from '../components/authentication/AuthenticationCarousel';
import Registration from '../components/authentication/Registration';
import Login from '../components/authentication/Login';

const Authentication = () => {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const [isLogin, setIsLogin] = useState(false);

  const handleSwitch = () => {
    setIsLogin((prev) => !prev);
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: isMobile ? 'column' : 'row', 
      width: '100%', 
      minHeight: 'calc(100vh - 140px)',
      gap: 2
    }}>
        <Box sx={{ 
          flex: 1, 
          width: '100%',
        }}>
          <AuthenticationCarousel/>
        </Box>

        <Box sx={{ 
          flex: 1, 
        }}>
          {isLogin ? (
            <Login onSwitch={handleSwitch} />
          ) : (
            <Registration onSwitch={handleSwitch} />
          )}
        </Box>
    </Box>
  );
};

export default Authentication;

import React, { useState } from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';

interface CustomButtonProps {
  text: string;
  onClick: () => void;
  defaultColor?: string;
  hoverColor?: string;
  textColor?: string;
  disabled?: boolean;
  fontSize?: string;
  maxWidth?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  onClick,
  defaultColor = '#3b3a38',
  hoverColor = '#fe4a23',
  textColor = '#ffffff',
  disabled = false,
  fontSize = 'clamp(1.5rem, 2vw, 2rem)',
  maxWidth = '400px',
}) => {
  const [hoverPosition, setHoverPosition] = useState({ x: '50%', y: '50%' });
  const [isHovered, setIsHovered] = useState(false);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setHoverPosition({ x: `${x}px`, y: `${y}px` });

    const offsetX = (x - rect.width / 2) * 0.035;
    const offsetY = (y - rect.height / 2) * 0.035;
    setTranslate({ x: offsetX, y: offsetY });
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setTranslate({ x: 0, y: 0 });
  };

  return (
    <ButtonBase
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      sx={{
        position: 'relative',
        backgroundColor: defaultColor,
        borderRadius: '8px',
        padding: '12px 24px',
        color: textColor,
        overflow: 'hidden',
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '26px',
        textTransform: 'none',
        transform: `translate(${translate.x}px, ${translate.y}px)`,
        transition: 'transform 0.3s ease-out, background-color 0.4s ease',
        width: '100%',
        maxWidth: maxWidth,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', 
      }}
    >
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '8px',
          '&::before': {
          content: '""',
          position: 'absolute',
          width: '300%',
          height: '300%',
          backgroundColor: hoverColor,
          borderRadius: '50%',
          top: `calc(${hoverPosition.y} - 150%)`,
          left: `calc(${hoverPosition.x} - 150%)`,
          transform: isHovered ? 'scale(1)' : 'scale(0)',
          transition: 'transform 0.8s ease-out',
          zIndex: 0,
          },
        }}/>
        <Typography sx={{ position: 'relative', zIndex: 1, fontSize: fontSize, fontWeight: 600 }}>{text}</Typography>
      </ButtonBase>
  );
};

export default CustomButton;
